import { mapHelper } from "@/utils/common.js";
const peopleRange = [
  { text: "全部", minCount: "", maxCount: "" },
  { text: "10人以下", minCount: 0, maxCount: 10 },
  { text: "10-30人", minCount: 10, maxCount: 30 },
  { text: "30-60人", minCount: 30, maxCount: 60 },
  { text: "60-100人", minCount: 60, maxCount: 100 },
  { text: "100-200人", minCount: 100, maxCount: 200 },
  { text: "200以人", minCount: 200, maxCount: "" },
];
const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];
const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);
const activityStatus = [
  {
    value: "1",
    label: "报名未开始",
  },
  {
    value: "2",
    label: "报名进行中",
  },
  {
    value: "3",
    label: "活动未开始",
  },
  {
    value: "4",
    label: "活动进行中",
  },
  {
    value: "5",
    label: "活动结束",
  },
  {
    value: "6",
    label: "活动取消",
  },
];
const relationList = [
  {
    key: 1,
    value: "本人",
  },
  {
    key: 2,
    value: "子女",
  },
  {
    key: 3,
    value: "父母",
  },
  {
    key: 4,
    value: "配偶",
  },
  {
    key: 5,
    value: "（外）祖父母",
  },
  {
    key: 6,
    value: "（外）孙子女",
  },
  {
    key: 7,
    value: "其他",
  },
];
const activityTypeList = [
  // {
  //   value: 1,
  //   label: "热门活动",
  // },
  // {
  //   value: 20,
  //   label: "时间银行",
  // },
  {
    value: 101,
    label: "金钟法制",
  },
];

const sceneIdsList = [
  {
    value: "1",
    checked: false,
    label: "未来邻里",
  },
  {
    value: "2",
    checked: false,
    label: "未来教育",
  },
  {
    value: "3",
    checked: false,
    label: "未来健康",
  },
  {
    value: "4",
    checked: false,
    label: "未来创业",
  },
  {
    value: "5",
    checked: false,
    label: "未来建筑",
  },
  {
    value: "6",
    checked: false,
    label: "未来交通",
  },
  {
    value: "7",
    checked: false,
    label: "未来低碳",
  },
  {
    value: "8",
    checked: false,
    label: "未来服务",
  },
  {
    value: "9",
    checked: false,
    label: "未来治理",
  },
];

const { map: sceneIdsListMap, setOps: sceneIdsListOps } =
  mapHelper.setMap(sceneIdsList);

const reportScenes = [
  {
    value: "1",
    checked: false,
    label: "技能培训",
  },
];
const { map: reportScenesMap, setOps: reportScenesOps } =
  mapHelper.setMap(reportScenes);

// 用户类别
const userType = [
  {
    value: "10",
    checked: false,
    label: "居民",
  },
  {
    value: "11",
    checked: false,
    label: "企业/商户",
  },
  // {
  //   value: "21",
  //   checked: false,
  //   label: "运营人员",
  // },
];
const { map: userTypeMap, setOps: setUserTypeOps } = mapHelper.setMap(userType);

export {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  peopleRange,
  activityStatus,
  relationList,
  activityTypeList,
  sceneIdsListMap,
  sceneIdsListOps,
  sceneIdsList,
  reportScenes,
  reportScenesMap,
  reportScenesOps,
  userTypeMap,
  setUserTypeOps,
};
